import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { parse } from "postgres-array";
import API from "../utils/API";

function ProjectReports() {
  const [searchParams] = useSearchParams();
  const [reports, setReports] = useState();

  useEffect(() => {
    const project_id = searchParams.get("puuid");
    if (project_id) {
      API.get(`/portal/project/${project_id}`, { params: { page: 0, limit: 999, type: "project" } })
        .then((r) => {
          setReports(r.data.data);
        })

        .catch((e) => console.log(e));
    }
  }, [searchParams]);

  return reports ? (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">Reports ({reports && reports.length})</div>
      </div>

      <table className="primary-table">
        <tr>
          <th>id</th>
          <th>Date</th>
          <th>Name</th>
          <th>Locations</th>
        </tr>
        <tbody>
          {reports &&
            reports.map((r) => (
              <tr key={r.id}>
                <td>{r.id}</td>
                <td>{format(parseISO(r.date), "dd MMM Y")}</td>
                <td>
                  <a href={r.file_url}>{`${r.savePrefix}-${r.id}`}</a>
                </td>
                <td>{r.pour_locations && [...new Set(parse(r.pour_locations))].map((pl, i) => <div key={i}>{pl}</div>)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : (
    "loading..."
  );
}

// [...new Set(cylinders && cylinders.filter((opt) => opt.days_requested > 6).map((c) => c.workorder_id))];

export default ProjectReports;
