import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { format, parseISO, formatDistance } from "date-fns";
import API from "../utils/API";

function ClientReports() {
  const [searchParams] = useSearchParams();
  const [reports, setReports] = useState();

  useEffect(() => {
    const client_id = searchParams.get("cuuid");
    if (client_id) {
      API.get(`/portal/client/${client_id}`, { params: { page: 0, limit: 250, type: "client" } })
        .then((r) => {
          setReports(r.data.data);
        })

        .catch((e) => console.log(e));
    }
  }, [searchParams]);

  return reports ? (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">Reports ({reports && reports.length})</div>
      </div>

      <table className="primary-table">
        <tr>
          <th>id</th>
          <th>Work Date</th>
          <th>Job</th>
          <th>Name</th>
          <th>Issue Date</th>
        </tr>
        <tbody>
          {reports &&
            reports.map((r) => (
              <tr key={r.id}>
                <td>{r.id}</td>
                <td>{format(parseISO(r.date), "dd MMM Y")}</td>
                <td>{r.project_name}{r.field_customer}
                <br/>{r.project_address_line_1} {r.project_address_line_2} {r.field_address_line_1} {r.field_address_line_2}</td>
                <td>
                  <a href={r.file_url}>{`${r.savePrefix}-${r.id}`}</a>
                </td>
                <td>
                  {formatDistance(
                        new parseISO(r.issue_date),
                        new Date(),
                      { addSuffix: true }
                    ) }
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : (
    "loading..."
  );
}

// [...new Set(cylinders && cylinders.filter((opt) => opt.days_requested > 6).map((c) => c.workorder_id))];

export default ClientReports;
